<template>
  <div>
    <div class="d-flex justify-space-between align-center">
      <div class="white--text"><p>Color</p></div>
      <div class="white--text cursor-pointer" @click="$emit('input', '')">
        <p>Clear Color</p>
      </div>
    </div>
    <div class="d-flex justify-center ml-12">
      <div class="reminderStatus py-3">
        <div class="statusCircle red" @click="onSelected('declined')"></div>
        <div
          class="statusCircle yellow mt-3"
          @click="onSelected('maybe')"
        ></div>
        <div
          class="statusCircle green mt-3"
          @click="onSelected('accepted')"
        ></div>
      </div>
      <div class="ml-5 px-2 py-3">
        <div :class="'statusText' + (value == 'declined' ? ' active' : '')">
          No
        </div>
        <div :class="'statusText mt-3' + (value == 'maybe' ? ' active' : '')">
          Maybe
        </div>
        <div
          :class="'statusText mt-3' + (value == 'accepted' ? ' active' : '')"
        >
          Yes
        </div>
      </div>
    </div>

    <div class="d-flex justify-center w-100 mt-10">
      <v-btn class="btn1 white--text mt-10" @click="$emit('select')">
        Select
      </v-btn>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ApplicationReminder',
    props: ['value'],
    methods: {
      onSelected(value) {
        return this.$emit('input', value);
      }
    }
  };
</script>
<style lang="scss">
  .reminderStatus {
    height: 300px;
    width: 115px;
    border-radius: 20px;
    background-color: black;
    border: 6px solid #ff2ff2;
  }
  .statusCircle {
    border-radius: 50%;
    width: 80px;
    margin: 0 auto;
    height: 80px;
    &.red {
      background-color: red;
    }
    &.yellow {
      background-color: red;
    }
    &.green {
      background-color: red;
    }
  }
  .statusText {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    &.active {
      color: #ff2ff2;
    }
  }
</style>
