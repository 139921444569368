<template>
  <div class="mt-10 w-100">
    <template v-if="currentTab != ''">
      <div v-if="me.role == 'talent'">
        <v-row
          no-gutters
          class="listBg py-5 px-8 mb-6"
          v-if="currentShow == 'current'"
        >
          <v-col
            cols="12"
            md="6"
            :class="!$isMobile() ? 'borderRight pr-4' : ''"
          >
            <v-row no-gutters>
              <v-col
                cols="6"
                sm="6"
                :class="$isMobile() ? 'text-center' : 'align-self-center'"
              >
                Available Casting Credit
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" dark size="20" class="mb-1">
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span
                    >Available Casting Credit refers to the number of videos a
                    user can upload/submit for a casting. Available Casting
                    Credit will also be deducted for any re-uploading of video
                    for the same casting.</span
                  >
                </v-tooltip>
              </v-col>
              <v-col
                cols="6"
                sm="6"
                :class="
                  $isMobile() ? 'text-center' : 'text-right align-self-center'
                "
              >
                <!-- <div :class="$isMobile() ? '' : 'd-flex'">
                  <div>
                    {{ remainingCredit.upload || 0}}
                  </div>
                  <div>
                    <v-btn class="btn1 outline fit">
                      Add Credit
                    </v-btn>
                  </div>
                </div> -->
                <template v-if="!$isMobile()">
                  {{ remainingCredit.upload || 0 }}
                  <v-btn
                    class="btn1 outline fit ml-2"
                    @click="
                      $router.push({
                        name: routeName.PAYMENT,
                        params: {
                          purchaseId: 'talent'
                        }
                      })
                    "
                  >
                    Add Credit
                  </v-btn>
                </template>
                <template v-else>
                  <div>{{ remainingCredit.upload || 0 }}</div>
                  <v-btn
                    class="btn1 outline fit ml-2"
                    @click="
                      $router.push({
                        name: routeName.PAYMENT,
                        params: {
                          purchaseId: 'talent'
                        }
                      })
                    "
                  >
                    Add Credit
                  </v-btn>
                </template>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" class="align-self-center">
            <v-row no-gutters>
              <v-col
                cols="6"
                sm="6"
                :class="$isMobile() ? 'text-center mt-3' : 'pl-4'"
              >
                Expire Soon
              </v-col>
              <v-col
                cols="6"
                sm="6"
                :class="$isMobile() ? 'text-center' : 'text-right'"
                >{{ remainingCredit.credit || 0 }}</v-col
              >
            </v-row>
          </v-col>
        </v-row>
      </div>
      <div v-if="!$isMobile()" class="d-flex w-100 justify-space-between">
        <div v-if="currentTab">
          <!-- <v-btn
            class="active tabBtn mr-3 mb-3"
            @click="loadSingleData(currentTab)"
          >
            {{ currentTabApplicationTitle }}
          </v-btn> -->
          <!-- <template v-for="application in applications">
            <v-btn
              :key="application.job_id"
              :class="
                (currentTab == application.job_id ? 'active ' : '') +
                'tabBtn mr-3 mb-3'
              "
              @click="loadSingleData(application.job_id)"
            >
              {{ application.title }}
            </v-btn>
          </template> -->
        </div>
        <div></div>
      </div>
      <div class="jobListing mt-5">
        <ApplicationItems
          v-if="currentTab != '' && currentApplication != null"
          :currentApplication="currentApplication"
          :remainingCredit="remainingCredit"
          :isApplicationHistory="isApplicationHistory"
          @refresh="loadSingleData(currentTab)"
          @loadSingleData="loadSingleData(currentTab)"
        />
      </div>
    </template>
    <template v-else>
      <div class="d-flex mb-12">
        <v-btn
          class="tabBtn me-5"
          :class="currentShow == 'current' ? 'active' : ''"
          @click="
            currentShow = 'current';
            loadListData();
          "
        >
          {{ $t('label.myApplication') }}
        </v-btn>
        <v-btn
          class="tabBtn me-5"
          :class="currentShow == 'current' ? '' : 'active'"
          @click="
            currentShow = 'history';
            loadListData();
          "
        >
          {{ $t('label.history') }}
        </v-btn>
      </div>
      <div
        v-for="application in applications"
        :key="application.job_id"
        class="listBg px-10 py-5 rounded-lg d-flex justify-space-between align-center mb-5 cursor-pointer"
        @click="loadSingleData(application.job_id)"
      >
        <h3 class="white--text font-weight-medium">
          {{ application.title }}
        </h3>
      </div>
    </template>

    <ModalBase id="applicationFilter" width="900"
      ><ApplicationFilter @apply="applyFilter"
    /></ModalBase>

    <ModalBase id="message" width="900"> </ModalBase>
  </div>
</template>

<script>
  import ApplicationItems from '@/views/ApplicationManagement/ApplicationItems.vue';
  import ApplicationFilter from '@/views/ApplicationManagement/ApplicationFilter.vue';
  import ModalBase from '@/components/base/ModalBase';
  import { APPLICATION_MANAGEMENT } from '@/api';
  import { mapGetters } from 'vuex';
  import { ROUTE_NAME } from '@/constants';

  export default {
    name: 'ApplicationListing',
    components: {
      ApplicationItems,
      ModalBase,
      ApplicationFilter
    },
    data: () => ({
      applications: [],
      currentApplication: null,
      remainingCredit: {
        upload: null,
        credit: null
      },
      routeName: ROUTE_NAME,
      currentShow: 'current'
    }),
    computed: {
      ...mapGetters({
        me: ['auth/me']
      }),
      currentTab() {
        return this?.$route?.query?.job_id || '';
      },
      currentTabApplicationTitle() {
        if (!this.currentTab) return null;
        const job = this.applications.find((x) => x.job_id == this.currentTab);
        return job ? job.title : null;
      },
      isApplicationHistory() {
        return !!this.$route?.query?.is_history;
      }
    },
    watch: {
      isApplicationHistory(val) {
        this.currentShow = val ? 'history' : 'current';
      },
      currentShow() {
        this.loadListData();
      }
    },
    methods: {
      async loadListData() {
        this.$startLoading();
        try {
          const data =
            this.currentShow == 'current'
              ? await APPLICATION_MANAGEMENT.getApplications()
              : await APPLICATION_MANAGEMENT.getApplicationsHistory();
          this.applications = data?.data?.items || [];
          this.$closeModal();
        } catch (e) {
          console.error(e);
          this.openAppDialogInfo(
            'error',
            e.error_message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
        this.$stopLoading();
      },
      async loadSingleData(job_id, query) {
        let pathQuery = {
          job_id
        };
        if (this.currentShow == 'history')
          pathQuery = {
            ...pathQuery,
            // is_history: this.currentShow == 'history' ? 1 : 0
            is_history: 0
          };
        this.$router.push({
          path: '/application_management',
          query: pathQuery
        });
        this.loadSingleJobData(query);
      },
      async loadSingleJobData(query) {
        this.$startLoading();
        this.currentApplication = null;
        try {
          const data = await APPLICATION_MANAGEMENT.getOneApplication(
            this.currentTab,
            {
              ...query,
              is_history: this.currentShow == 'current' ? false : true
            }
          );
          if (this.me.role == 'talent') {
            this.remainingCredit = {
              upload: data?.data?.upload_remaining,
              credit: data?.data?.credit_remaining
            };
          }

          this.currentApplication = data?.data?.items || [];
          this.$closeModal();
        } catch (e) {
          console.error(e);
          this.openAppDialogInfo(
            'error',
            e.error_message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
        this.$stopLoading();
      },
      applyFilter(query) {
        this.loadSingleData(this.currentTab, query);
      },
      openFilter() {
        this.$openModal('jobFilter');
      }
    },
    mounted() {
      this.loadListData();
      if (this.$route?.query?.is_history) this.currentShow = 'history';
      if (this.currentTab) {
        this.loadSingleData(this?.$route?.query?.job_id);
      }
    }
  };
</script>
<style scoped lang="scss">
  .borderRight {
    border-right: 1px solid #fff;
  }
  .v-tooltip__content {
    max-width: 300px;
  }
</style>
