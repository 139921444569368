<template>
  <div class="container wrapper wrapper_space-top">
    <div>
      <div class="d-flex">
        <div v-if="$route.query.job_id" class="align-self-center mr-5">
          <v-icon 
            color="primary" 
            @click="$router.push({
              path: '/application_management'
            });"
          >mdi-arrow-left-thick</v-icon>
        </div>
        <h1 class="pageHeader">
          Application Management
          <v-icon 
            dark 
            @click="$openModal('indicatorModal')"
          >mdi-alert-circle-outline</v-icon>
        </h1>
      </div>
      <ApplicationListing />
    </div>
    <ModalBase id="indicatorModal" width="900" v-if="!isTalentRole">
      <div>
        <h1 class="indicatorTitle">Indicator</h1>
        <div class="mt-6 w-100">
          <div class="groupClass align-center">
            <div class="leftIcon">
              <v-btn class="btn1 fit">Send Reminder</v-btn>
            </div>
            <!-- <div class="rightText">
              <span class="pink2--text">Send Reminder </span><br />
              Reminds talent that had received an invitation but is yet to
              respond to the invitation
            </div> -->
            <div class="rightText">
              <span class="pink2--text">Send Reminder </span><br />
              Reminds talent to submit a video for the casting
            </div>
          </div>
          <div class="groupClass align-center" v-if="false">
            <div class="leftIcon">
              <v-btn class="btn1 fit">Request A Video</v-btn>
            </div>
            <div class="rightText">
              <span class="pink2--text">Request A Video </span><br />
              Reminds talent that had applied for a casting but is yet to submit
              a video
            </div>
          </div>

          <div class="groupClass align-center">
            <div class="leftIcon">
              <div class="underlineBtn">Update Video Status</div>
            </div>
            <div class="rightText">
              <span class="pink2--text">Update Video Status</span><br />
              Requests talent / talent agency to resubmit a new video for the
              casting if video status is rejected
            </div>
          </div>

          <div class="groupClass align-center">
            <div class="leftIcon">
              <v-img
                class="ml-12"
                contain
                src="/assets/image/icons/download.png"
                height="25"
                width="25"
              />
            </div>
            <div class="rightText">
              <span class="pink2--text">Download Video </span><br />
              Download the video submitted by talent
            </div>
          </div>
          <div class="groupClass align-center">
            <div class="leftIcon">
              <v-img
                class="ml-10"
                contain
                src="/assets/image/icons/whiteChat.png"
                height="40"
                width="40"
              />
            </div>
            <div class="rightText">
              <span class="pink2--text">Chat Button </span><br />
              Chat with agency, production house and talent
            </div>
          </div>
          <div class="groupClass align-center" v-if="false">
            <div class="leftIcon">
              <v-img
                class="ml-12"
                contain
                src="/assets/image/icons/dots.png"
                height="25"
                width="25"
              />
            </div>
            <div class="rightText">
              <span class="pink2--text">3 dots </span><br />
              Responds/Updates the status of the job application
            </div>
          </div>
        </div>
      </div>
    </ModalBase>
    <ModalBase id="indicatorModal" width="900" v-else>
      <div>
        <h1 class="indicatorTitle">Indicator</h1>
        <div class="mt-6 w-100">
          <div class="groupClass align-center">
            <div class="leftIcon">
              <v-btn class="btn1 fit">Upload Video</v-btn>
            </div>
            <div class="rightText">
              <span class="pink2--text">Upload Video </span><br />
              Submit a video for the casting
            </div>
          </div>
          <div class="groupClass align-center">
            <div class="leftIcon">
              <v-btn class="btn1 fit">Play Video</v-btn>
            </div>
            <div class="rightText">
              <span class="pink2--text">Play Video </span><br />
              Play the submitted video
            </div>
          </div>

          <div class="groupClass align-center">
            <div class="leftIcon">
              <v-btn class="btn1 fit outline">Resubmit Video</v-btn>
            </div>
            <div class="rightText">
              <span class="pink2--text">Resubmit Video </span><br />
              Allow talent to submit a new video
            </div>
          </div>

          <div class="groupClass align-center">
            <div class="leftIcon">
              <v-img
                class="ml-12"
                contain
                src="/assets/image/icons/download.png"
                height="25"
                width="25"
              />
            </div>
            <div class="rightText">
              <span class="pink2--text">Download Video </span><br />
              Download the video submitted by talent
            </div>
          </div>
          <div class="groupClass align-center">
            <div class="leftIcon">
              <v-img
                class="ml-10"
                contain
                src="/assets/image/icons/whiteChat.png"
                height="40"
                width="40"
              />
            </div>
            <div class="rightText">
              <span class="pink2--text">Chat Button </span><br />
              Chat with agency, production house and talent
            </div>
          </div>
        </div>
      </div>
    </ModalBase>
  </div>
</template>

<script>
  import ApplicationListing from '@/views/ApplicationManagement/ApplicationListing.vue';
  import ModalBase from '@/components/base/ModalBase';
  import { mapGetters } from 'vuex';

  export default {
    name: 'ApplicationManagement',
    components: {
      ApplicationListing,
      ModalBase
    },
    computed: {
      ...mapGetters({
        me: ['auth/me']
      }),

      isTalentRole() {
        return this?.me?.role === 'talent' || this?.me?.role === 'agency';
      }
    }
  };
</script>
<style lang="scss" scoped>
  .indicatorTitle {
    font-size: 2.5rem;
    font-weight: bold;
    text-transform: uppercase;
  }
  .leftIcon {
    width: 30%;
  }
  .rightText {
    width: 70%;
  }
  .groupClass {
    display: flex;
    margin-top: 28px;
    @media (max-width: 720px) {
      display: block;
      &:not(:first-child) {
        margin-top: 50px;
      }

      .rightText {
        width: 100%;
        margin: 5px 0px 0px 10px;
      }
      .leftIcon {
        width: 100%;
      }
    }
  }
</style>
