<template>
  <div>
    <div class="d-flex justify-space-between align-center">
      <div class="white--text"><h2>FILTER</h2></div>
      <div class="white--text cursor-pointer" @click="reset">
        <h2>CLEAR</h2>
      </div>
    </div>
    <v-row class="mt-6" justify-md="space-between">
      <!-- <v-col cols="12" sm="4">
        <h2 class="white--text">ROLE</h2>
        <br />

        <CustomSelect :options="options" v-model="casting_role_id" />
      </v-col> -->
      <v-col cols="12" sm="7">
        <h2 class="white--text">CHANNEL</h2>

        <br />
        <CustomSelect
          :options="applicationManagementChannelFilter"
          v-model="filter.channel"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <h2 class="white--text">STATUS</h2>
        <br />

        <CustomSelect
          :options="applicationStatusList"
          v-model="filter.status"
        />
      </v-col>
      <v-col cols="12" sm="7">
        <h2 class="white--text">APPLICATION</h2>

        <br />
        <CustomSelect
          :options="applicationManagementApplicationFilter"
          v-model="filter.application"
        />
      </v-col>
    </v-row>
    <div class="text-center mt-10">
      <v-btn class="btn1 white--text mt-10" @click="$emit('apply', filter)">
        Apply
      </v-btn>
    </div>
  </div>
</template>

<script>
  import CustomSelect from '@/components/inputs/CustomSelect.vue';

  export default {
    name: 'ApplicationFilter',
    components: { CustomSelect },
    methods: {
      reset() {
        this.filter = {
          casting_role_id: '',
          status: '',
          channel: '',
          application: ''
        };
      }
    },
    computed: {
      applicationStatusList() {
        return this?.$store?.getters['constants/applicationStatusList'];
      },
      applicationManagementChannelFilter() {
        return this?.$store?.getters[
          'constants/applicationManagementChannelFilter'
        ];
      },
      applicationManagementApplicationFilter() {
        return this?.$store?.getters[
          'constants/applicationManagementApplicationFilter'
        ];
      }
    },
    data: () => ({
      options: [{ title: 'All', value: '' }],
      filter: {
        casting_role_id: '',
        status: '',
        channel: '',
        application: ''
      }
    })
  };
</script>
<style lang="scss">

</style>
