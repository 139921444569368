import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-10 w-100"},[(_vm.currentTab != '')?[(_vm.me.role == 'talent')?_c('div',[(_vm.currentShow == 'current')?_c(VRow,{staticClass:"listBg py-5 px-8 mb-6",attrs:{"no-gutters":""}},[_c(VCol,{class:!_vm.$isMobile() ? 'borderRight pr-4' : '',attrs:{"cols":"12","md":"6"}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{class:_vm.$isMobile() ? 'text-center' : 'align-self-center',attrs:{"cols":"6","sm":"6"}},[_vm._v(" Available Casting Credit "),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"mb-1",attrs:{"dark":"","size":"20"}},on),[_vm._v(" mdi-information-outline ")])]}}],null,false,1353609146)},[_c('span',[_vm._v("Available Casting Credit refers to the number of videos a user can upload/submit for a casting. Available Casting Credit will also be deducted for any re-uploading of video for the same casting.")])])],1),_c(VCol,{class:_vm.$isMobile() ? 'text-center' : 'text-right align-self-center',attrs:{"cols":"6","sm":"6"}},[(!_vm.$isMobile())?[_vm._v(" "+_vm._s(_vm.remainingCredit.upload || 0)+" "),_c(VBtn,{staticClass:"btn1 outline fit ml-2",on:{"click":function($event){return _vm.$router.push({
                      name: _vm.routeName.PAYMENT,
                      params: {
                        purchaseId: 'talent'
                      }
                    })}}},[_vm._v(" Add Credit ")])]:[_c('div',[_vm._v(_vm._s(_vm.remainingCredit.upload || 0))]),_c(VBtn,{staticClass:"btn1 outline fit ml-2",on:{"click":function($event){return _vm.$router.push({
                      name: _vm.routeName.PAYMENT,
                      params: {
                        purchaseId: 'talent'
                      }
                    })}}},[_vm._v(" Add Credit ")])]],2)],1)],1),_c(VCol,{staticClass:"align-self-center",attrs:{"cols":"12","md":"6"}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{class:_vm.$isMobile() ? 'text-center mt-3' : 'pl-4',attrs:{"cols":"6","sm":"6"}},[_vm._v(" Expire Soon ")]),_c(VCol,{class:_vm.$isMobile() ? 'text-center' : 'text-right',attrs:{"cols":"6","sm":"6"}},[_vm._v(_vm._s(_vm.remainingCredit.credit || 0))])],1)],1)],1):_vm._e()],1):_vm._e(),(!_vm.$isMobile())?_c('div',{staticClass:"d-flex w-100 justify-space-between"},[(_vm.currentTab)?_c('div'):_vm._e(),_c('div')]):_vm._e(),_c('div',{staticClass:"jobListing mt-5"},[(_vm.currentTab != '' && _vm.currentApplication != null)?_c('ApplicationItems',{attrs:{"currentApplication":_vm.currentApplication,"remainingCredit":_vm.remainingCredit,"isApplicationHistory":_vm.isApplicationHistory},on:{"refresh":function($event){return _vm.loadSingleData(_vm.currentTab)},"loadSingleData":function($event){return _vm.loadSingleData(_vm.currentTab)}}}):_vm._e()],1)]:[_c('div',{staticClass:"d-flex mb-12"},[_c(VBtn,{staticClass:"tabBtn me-5",class:_vm.currentShow == 'current' ? 'active' : '',on:{"click":function($event){_vm.currentShow = 'current';
          _vm.loadListData();}}},[_vm._v(" "+_vm._s(_vm.$t('label.myApplication'))+" ")]),_c(VBtn,{staticClass:"tabBtn me-5",class:_vm.currentShow == 'current' ? '' : 'active',on:{"click":function($event){_vm.currentShow = 'history';
          _vm.loadListData();}}},[_vm._v(" "+_vm._s(_vm.$t('label.history'))+" ")])],1),_vm._l((_vm.applications),function(application){return _c('div',{key:application.job_id,staticClass:"listBg px-10 py-5 rounded-lg d-flex justify-space-between align-center mb-5 cursor-pointer",on:{"click":function($event){return _vm.loadSingleData(application.job_id)}}},[_c('h3',{staticClass:"white--text font-weight-medium"},[_vm._v(" "+_vm._s(application.title)+" ")])])})],_c('ModalBase',{attrs:{"id":"applicationFilter","width":"900"}},[_c('ApplicationFilter',{on:{"apply":_vm.applyFilter}})],1),_c('ModalBase',{attrs:{"id":"message","width":"900"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }