<template>
  <div>
    <div class="filter_row d-flex">
      <template v-if="$isMobile()">
        <div>{{ applicationTitle }}</div>
        <v-icon dark class="ml-auto mr-4" @click="secondLayer = !secondLayer">
          {{ secondLayer ? 'mdi-table' : 'mdi-image-filter-none' }}
        </v-icon>
      </template>
      <template v-else>
        <v-btn class="active tabBtn" @click="$emit('loadSingleData')">
          {{ applicationTitle }}
        </v-btn>
      </template>
      <div :class="$isMobile() ? '' : 'ml-auto'" class="align-self-center">
        <v-icon dark @click="$openModal('applicationFilter')">
          mdi-tune
        </v-icon>
      </div>
    </div>
    <!-- START OF DESKTOP LIST VIEW -->
    <div class="mt-10" v-if="!$isMobile()">
      <span class="sm-font grey--text"
        >*Tips: Hover on the icons for more details</span
      >
      <template v-for="(role, index) in applications">
        <v-data-table
          class="application_datatable"
          :key="index"
          :headers="headers"
          :items="role.items"
          dark
          hide-default-footer
          mobile-breakpoint="0"
        >
          <!-- custom datatable header -->
          <template v-slot:header.role_name>
            <router-link
              class="role-name_title"
              :to="{
                name: routeName.JOB_DETAILS,
                slug: $route.query.job_id,
                params: {
                  id: $route.query.job_id
                }
              }"
            >
              {{ role.roleInfo.character }}
            </router-link>
          </template>
          <!-- custom datatable item -->
          <template v-slot:item.role_name="{ item }">
            <div v-if="item.chat_group" class="py-3">
              <v-avatar
                class="cursor-pointer"
                v-for="user in item.chat_group.users"
                :key="user.user_id"
                color="primary"
                @click="
                  $router.push({
                    name: routeName.PROFILE,
                    query: {
                      id: user.user_id
                    }
                  })
                "
              >
                <img :src="user.headshot_file_path" crossorigin="anonymous" />
              </v-avatar>
              <div class="mt-4">
                {{ getUserNames(item.chat_group.users) }}
              </div>
            </div>
            <template v-else>
              <span
                class="cursor-pointer"
                @click="
                  $router.push({
                    name: routeName.PROFILE,
                    query: {
                      id: item.talent.id
                    }
                  })
                "
                >{{ item.talent.fname }}</span
              >
            </template>
          </template>
          <template v-slot:item.interest="{ item }">
            <v-tooltip
              bottom
              v-if="
                ['interested'].includes(item.invitation_status) ||
                (item.id && !isTalentRole)
              "
            >
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="success"> mdi-check </v-icon>
              </template>
              <span>Talent is interested in this role</span>
            </v-tooltip>
          </template>
          <template v-slot:item.video="{ item }">
            <template>
              <v-tooltip bottom v-if="item.video_file">
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    :color="
                      ['submitted', 'approved'].includes(item.video_status)
                        ? 'success'
                        : 'white'
                    "
                  >
                    mdi-check
                  </v-icon>
                </template>
                <span>{{ getButtonMessage(item.video_status) }}</span>
              </v-tooltip>
              <!-- <v-icon
                :color="
                  ['submitted', 'approved'].includes(item.video_status)
                    ? 'success'
                    : 'white'
                "
                class="status_icon"
              >mdi-check
              </v-icon> -->
            </template>
          </template>
          <template v-slot:item.status="{ item }">
            <v-tooltip bottom v-if="item.status">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  :color="application_status[item.status || 'pending']"
                >
                  mdi-circle
                </v-icon>
              </template>
              <span>{{ $t(`label.${item.status}`) }}</span>
            </v-tooltip>
            <v-tooltip bottom v-else-if="!item.status && isApplicationHistory">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="white"> mdi-circle </v-icon>
              </template>
              <span>{{ currentApplication[0].job_status }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.details="{ item }">
            <span class="font-weight-bold">{{
              item.channel | capitalize
            }}</span>
            <div class="sm-font mt-4">
              Last Video Submission
              <br />
              {{
                item.video_submission_date
                  ? defaultDate(item.video_submission_date)
                  : '-'
              }}
            </div>
          </template>
          <template v-slot:item.action="{ item }">
            <template v-if="!isApplicationHistory">
              <template v-if="!isTalentRole">
                <template v-if="item.id">
                  <div class="text-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          class="btn1 fit white--text"
                          @click="actionBtn(item)"
                        >
                          {{ item.video_file ? 'Play Video' : 'Send Reminder' }}
                        </v-btn>
                      </template>
                      <span>{{ getButtonMessage(item.video_status) }}</span>
                    </v-tooltip>
                  </div>
                  <div
                    v-if="item.video_file && item.video_status != 'viewed'"
                    class="pt-3 text-center"
                  >
                    <a
                      class="underlineBtn d-flex justify-center"
                      @click="
                        selectedItem = item;
                        $openModal('requestVideoModal');
                      "
                    >
                      Update Video Status
                    </a>
                  </div>
                  <div
                    v-else-if="item.video_file && item.video_status == 'viewed'"
                    class="pt-3 text-center"
                  >
                    <a
                      class="underlineBtn d-flex justify-center"
                      @click="
                        id = item.id;
                        selectedItem = null;
                        $openModal('applicationReminder');
                      "
                    >
                      Update Application Status
                    </a>
                  </div>
                </template>
                <template
                  v-else-if="
                    item.invitation_id && item.invitation_status == 'interested'
                  "
                >
                  <div class="application-btn_column text-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          class="btn1 fit white--text"
                          @click="invitationReminder(item.invitation_id)"
                        >
                          Send Reminder
                        </v-btn>
                      </template>
                      <span>Remind talent to apply for the job</span>
                    </v-tooltip>
                  </div>
                </template>
                <template
                  v-else-if="
                    item.invitation_id && item.invitation_status == '-'
                  "
                >
                  <div class="application-btn_column text-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          class="btn1 fit white--text w-100"
                          @click="reinvite($route.query.job_id, item)"
                        >
                          Reinvite
                        </v-btn>
                      </template>
                      <span>Remind talent to apply for the job</span>
                    </v-tooltip>
                  </div>
                </template>
              </template>
              <template v-else>
                <template v-if="item.id">
                  <div v-if="me.role == 'agency'" class="text-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          class="btn1 fit white--text align-self-center"
                          @click="actionBtn(item)"
                        >
                          {{ item.video_file ? 'Play Video' : 'Send Reminder' }}
                        </v-btn>
                      </template>
                      <span>{{ getButtonMessage(item.video_status) }}</span>
                    </v-tooltip>
                  </div>
                  <input
                    :id="'fileUpload-' + item.id"
                    :ref="'videoFile-' + item.id"
                    type="file"
                    accept="video/mp4,video/x-m4v,video/*"
                    @input="openPreviewVideoModal($event, item)"
                    hidden
                  />
                  <div
                    v-if="me.role != 'agency' && item.video_file"
                    class="text-center"
                  >
                    <v-btn class="btn1 fit" @click="actionBtn(item)">
                      Play Video
                    </v-btn>
                  </div>
                  <template v-if="!item.video_file">
                    <div class="text-center">
                      <v-btn
                        class="btn1 fit white--text my-3 align-self-center"
                        :class="me.role == 'agency' ? 'outline' : ''"
                        @click="openFile(item.id)"
                        >Upload Video</v-btn
                      >
                    </div>
                    <div v-if="me.role == 'talent'" class="text-center">
                      <v-btn
                        class="btn1 fit outline"
                        @click="withdrawApplication(item.id)"
                      >
                        {{ $t('label.withdrawApplication') }}
                      </v-btn>
                    </div>
                  </template>

                  <div
                    v-else-if="item.video_file && item.video_status != 'viewed'"
                    class="text-center mt-2"
                  >
                    <v-btn class="btn1 fit outline" @click="openFile(item.id)">
                      Resubmit Video
                    </v-btn>
                  </div>
                </template>
              </template>
            </template>
          </template>
          <template v-slot:item.filter="{ item }">
            <template v-if="!isApplicationHistory">
              <v-row no-gutters justify="end" v-if="item.id">
                <v-col
                  cols="12"
                  sm="4"
                  class="text-right"
                  v-if="item.video_file"
                >
                  <v-icon
                    dark
                    @click="downloadVideo(item.public_video_file, item)"
                  >
                    mdi-tray-arrow-down
                  </v-icon>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  class="text-right"
                  v-if="
                    item.chat_group && checkUserIncluded(item.chat_group.users)
                  "
                >
                  <v-icon dark @click="openChat(item.chat_group.chat_group_id)">
                    mdi-message-outline
                  </v-icon>
                </v-col>
                <v-col cols="12" sm="4" class="text-right" v-if="isTalentRole">
                  <v-menu bottom left offset-y dark>
                    <template v-slot:activator="{ on }">
                      <v-icon dark icon v-on="on">mdi-dots-vertical</v-icon>
                    </template>
                    <v-list>
                      <v-list-item @click="withdrawApplication(item.id)">
                        <v-list-item-title class="text-capitalize">{{
                          $t('label.withdrawApplication')
                        }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </template>
          </template>
        </v-data-table>
      </template>
    </div>
    <!-- END OF DESKTOP LIST VIEW -->

    <!-- START OF MOBILE VIEW -->
    <div v-else class="mt-5">
      <template v-for="(role, index) in applications">
        <v-data-table
          v-if="!secondLayer"
          class="application_datatable"
          :key="index"
          :headers="mobileListHeaders"
          :items="role.items"
          dark
          hide-default-footer
          mobile-breakpoint="0"
        >
          <!-- custom datatable header -->
          <template v-slot:header.role_name>
            <router-link
              class="role-name_title"
              :to="{
                name: routeName.JOB_DETAILS,
                slug: $route.query.job_id,
                params: {
                  id: $route.query.job_id
                }
              }"
            >
              {{ role.roleInfo.character }}
            </router-link>
          </template>
          <!-- custom datatable item -->
          <template v-slot:item.role_name="{ item }">
            <div v-if="item.chat_group" class="py-3">
              <v-avatar
                class="cursor-pointer"
                v-for="user in item.chat_group.users"
                :key="user.user_id"
                color="primary"
                @click="
                  $router.push({
                    name: routeName.PROFILE,
                    query: {
                      id: user.user_id
                    }
                  })
                "
              >
                <img :src="user.headshot_file_path" crossorigin="anonymous" />
              </v-avatar>
              <div class="mt-4">
                {{ getUserNames(item.chat_group.users) }}
              </div>
            </div>
            <template v-else>
              <span
                class="cursor-pointer"
                @click="
                  $router.push({
                    name: routeName.PROFILE,
                    query: {
                      id: item.talent.id
                    }
                  })
                "
                >{{ item.talent.fname }}</span
              >
            </template>
          </template>
          <template v-slot:item.interest="{ item }">
            <v-tooltip
              bottom
              v-if="
                ['interested'].includes(item.invitation_status) ||
                (item.id && !isTalentRole)
              "
            >
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="success"> mdi-check </v-icon>
              </template>
              <span>Talent is interested in this role</span>
            </v-tooltip>
          </template>
          <template v-slot:item.video="{ item }">
            <template>
              <v-tooltip bottom v-if="item.video_file">
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    :color="
                      ['submitted', 'approved'].includes(item.video_status)
                        ? 'success'
                        : 'white'
                    "
                  >
                    mdi-check
                  </v-icon>
                </template>
                <span>{{ getButtonMessage(item.video_status) }}</span>
              </v-tooltip>
              <!-- <v-icon
                :color="
                  ['submitted', 'approved'].includes(item.video_status)
                    ? 'success'
                    : 'white'
                "
                class="status_icon"
              >mdi-check
              </v-icon> -->
            </template>
          </template>
          <template v-slot:item.status="{ item }">
            <v-tooltip bottom v-if="item.status">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  :color="application_status[item.status || 'pending']"
                >
                  mdi-circle
                </v-icon>
              </template>
              <span>{{ $t(`label.${item.status}`) }}</span>
            </v-tooltip>
            <v-tooltip bottom v-else-if="!item.status && isApplicationHistory">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="white"> mdi-circle </v-icon>
              </template>
              <span>{{ currentApplication[0].job_status }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <v-data-table
          v-else
          class="mobile-second-layer-application_datatable"
          :key="index"
          :headers="mobileSecondLayerHeaders"
          :items="role.items"
          dark
          hide-default-footer
          mobile-breakpoint="0"
        >
          <!-- custom datatable header -->
          <template v-slot:header.role_name>
            <router-link
              class="role-name_title"
              :to="{
                name: routeName.JOB_DETAILS,
                slug: $route.query.job_id,
                params: {
                  id: $route.query.job_id
                }
              }"
            >
              {{ role.roleInfo.character }}
            </router-link>
          </template>
          <!-- custom datatable item -->
          <template v-slot:item.role_name="{ item }">
            <div class="d-flex justify-end mb-2 pt-3">
              <div
                class="px-2"
                v-if="
                  item.chat_group && checkUserIncluded(item.chat_group.users)
                "
              >
                <v-icon dark @click="openChat(item.chat_group.chat_group_id)">
                  mdi-message-outline
                </v-icon>
              </div>
              <div class="px-2" v-if="isTalentRole">
                <v-menu bottom left offset-y dark>
                  <template v-slot:activator="{ on }">
                    <v-icon dark icon v-on="on">mdi-dots-vertical</v-icon>
                  </template>
                  <v-list>
                    <v-list-item @click="withdrawApplication(item.id)">
                      <v-list-item-title class="text-capitalize">{{
                        $t('label.withdrawApplication')
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
            <!-- casting involved users row -->
            <div class="mb-8">
              <template v-if="item.chat_group">
                <div>
                  <v-avatar
                    class="cursor-pointer"
                    v-for="user in item.chat_group.users"
                    :key="user.user_id"
                    color="primary"
                    @click="
                      $router.push({
                        name: routeName.PROFILE,
                        query: {
                          id: user.user_id
                        }
                      })
                    "
                  >
                    <img
                      :src="user.headshot_file_path"
                      crossorigin="anonymous"
                    />
                  </v-avatar>
                </div>
                <div class="mt-4">
                  {{ getUserNames(item.chat_group.users) }}
                </div>
              </template>
              <template v-else>
                <span
                  class="cursor-pointer"
                  @click="
                    $router.push({
                      name: routeName.PROFILE,
                      query: {
                        id: item.talent.id
                      }
                    })
                  "
                  >{{ item.talent.fname }}</span
                >
              </template>
            </div>
            <v-row no-gutters class="application-details_row">
              <v-col cols="6">
                <div class="mini-font">Channel</div>
                <div class="mt-2">{{ item.channel | capitalize }}</div>
              </v-col>
              <v-col cols="6">
                <div class="mini-font">Status</div>
                <div class="mt-2" v-if="item.status">
                  <v-icon :color="application_status[item.status || 'pending']">
                    mdi-circle
                  </v-icon>
                  {{ item.status | capitalize }}
                </div>
                <div class="mt-2" v-if="!item.status && isApplicationHistory">
                  <v-icon color="white"> mdi-circle </v-icon>
                  {{ currentApplication[0].job_status | capitalize }}
                </div>
              </v-col>
              <v-col cols="6">
                <div class="mini-font">Invitation Status</div>
                <div class="mt-2 text-capitalize">
                  {{ item.invitation_status }}
                </div>
              </v-col>
              <template v-if="item.video_status">
                <v-col cols="6">
                  <div class="mini-font">Last Video Submission</div>
                  <div class="mt-2">
                    {{
                      item.video_submission_date
                        ? defaultDate(item.video_submission_date)
                        : '-'
                    }}
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="mini-font">Video File</div>
                  <div class="align-self-end d-flex mt-2">
                    <div>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" dark>
                            mdi-information-outline
                          </v-icon>
                        </template>
                        <span>{{ getButtonMessage(item.video_status) }}</span>
                      </v-tooltip>
                    </div>
                    <div class="px-3" v-if="item.video_file">
                      <v-icon
                        dark
                        @click="downloadVideo(item.public_video_file, item)"
                      >
                        mdi-tray-arrow-down
                      </v-icon>
                    </div>
                  </div>

                  <!-- <div class="px-4" v-if="item.chat_group">
                    <v-icon
                      dark
                      @click="openChat(item.chat_group.chat_group_id)"
                    >
                      mdi-message-outline
                    </v-icon>
                  </div> -->
                </v-col>
              </template>
            </v-row>

            <div v-if="!isApplicationHistory" class="pb-5">
              <v-row class="mt-8">
                <!-- prod house / freelancer view -->
                <v-col cols="12" v-if="!isTalentRole" class="text-center">
                  <!-- case: is active application -->
                  <template v-if="item.id">
                    <div class="mobileButtonContainer">
                      <v-btn
                        class="btn1 fit white--text"
                        @click="actionBtn(item)"
                        >{{
                          item.video_file ? 'Play Video' : 'Send Reminder'
                        }}</v-btn
                      >
                      <!-- <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            dark
                            size="20"
                            class="mobileTooltipPosition"
                          >
                            mdi-information-outline
                          </v-icon>
                        </template>
                        <span>{{
                          item.video_file
                            ? getButtonMessage(item.video_status)
                            : 'No video submitted yet'
                        }}</span>
                      </v-tooltip> -->
                    </div>

                    <!-- case: has video file and video status not viewed -->
                    <div
                      class="mt-4"
                      v-if="item.video_file && item.video_status != 'viewed'"
                    >
                      <span
                        class="underlineBtn cursor-pointer my-3"
                        @click="
                          selectedItem = item;
                          $openModal('requestVideoModal');
                        "
                      >
                        Update Video Status
                      </span>
                    </div>
                    <div
                      class="mt-4"
                      v-else-if="
                        item.video_file && item.video_status == 'viewed'
                      "
                    >
                      <span
                        class="underlineBtn cursor-pointer my-3"
                        @click="
                          id = item.id;
                          selectedItem = null;
                          $openModal('applicationReminder');
                        "
                      >
                        Update Application Status
                      </span>
                    </div>
                  </template>

                  <!-- case: is invitation -->
                  <template
                    v-else-if="
                      item.invitation_id &&
                      item.invitation_status == 'interested'
                    "
                  >
                    <div class="mobileButtonContainer">
                      <v-btn
                        class="btn1 fit white--text"
                        @click="invitationReminder(item.invitation_id)"
                      >
                        Send Reminder
                      </v-btn>
                      <v-tooltip
                        bottom
                        v-if="
                          item.invitation_id &&
                          item.invitation_status == 'interested'
                        "
                      >
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" dark class="mobileTooltipPosition">
                            mdi-information-outline
                          </v-icon>
                        </template>
                        <span>Remind talent to apply for the job </span>
                      </v-tooltip>
                    </div>
                  </template>
                </v-col>
                <!-- talent agency / talent view -->
                <v-col cols="12" v-else class="text-center">
                  <input
                    :id="'fileUpload-' + item.id"
                    :ref="'videoFile-' + item.id"
                    type="file"
                    accept="video/mp4,video/x-m4v,video/*"
                    @input="openPreviewVideoModal($event, item)"
                    hidden
                  />
                  <!-- case: has video file -->
                  <div class="mobileButtonContainer">
                    <template v-if="me.role == 'agency'">
                      <v-btn class="btn1 fit" @click="actionBtn(item)">
                        {{ item.video_file ? 'Play Video' : 'Send Reminder' }}
                      </v-btn>
                    </template>
                    <template
                      v-else-if="me.role != 'agency' && item.video_file"
                    >
                      <v-btn class="btn1 fit" @click="actionBtn(item)">
                        Play Video
                      </v-btn>
                    </template>
                  </div>

                  <!-- case: no video file -->
                  <template v-if="!item.video_file">
                    <div class="mobileButtonContainer">
                      <v-btn
                        class="btn1 fit my-3"
                        :class="me.role == 'agency' ? 'outline' : ''"
                        @click="openFile(item.id)"
                        >Upload Video</v-btn
                      >
                    </div>
                    <div v-if="me.role == 'talent'">
                      <v-btn
                        class="btn1 fit outline"
                        @click="withdrawApplication(item.id)"
                      >
                        {{ $t('label.withdrawApplication') }}
                      </v-btn>
                    </div>
                  </template>

                  <!-- case: has video file -->
                  <div
                    class="mobileButtonContainer"
                    v-else-if="item.video_file && item.video_status != 'viewed'"
                  >
                    <v-btn
                      class="btn1 fit outline my-3"
                      @click="openFile(item.id)"
                    >
                      Resubmit Video
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </div>
          </template>
        </v-data-table>
      </template>
    </div>
    <ModalBase id="applicationReminder" width="600" @closeModal="resetModal"
      ><ApplicationReminder v-model="status" @select="approveApplication"
    /></ModalBase>
    <ModalBase id="requestVideoModal">
      <div class="text-center">
        <h1 class="text-uppercase">
          Accept this video or request a resubmission of the video?
        </h1>
        <v-row no-gutters justify="center" class="mt-10">
          <v-col cols="12" sm="6">
            <v-btn class="btn1" @click="updateVideoStatus('viewed')">
              Accept
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6">
            <v-btn
              class="btn1 outline mt-sm-0 mt-5"
              @click="updateVideoStatus('declined')"
            >
              Resubmit
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </ModalBase>
    <AppPreviewVideoModal @closeModal="previewVideoModalClose" />
  </div>
</template>

<script>
  import { APPLICATION_MANAGEMENT } from '@/api';
  import {
    APPLICATION_MANAGEMENT_WITHDRAW_APPLICATION,
    APPLICATION_MANAGEMENT_INITIAL_WITHDRAW_APPLICATION_STATE
  } from '@/store/application-management.module';
  import ApplicationReminder from '@/views/ApplicationManagement/ApplicationReminder.vue';
  // import CharacterDetailsModal from '@/components/applicationManagement/CharacterDetailsModal.vue';
  import ModalBase from '@/components/base/ModalBase';
  import { mapGetters } from 'vuex';
  import Moment from 'moment';
  import { DEFAULT_DATE_FORMAT } from '@/constants/constants';
  import { bus } from '@/main.js';
  import { ROUTE_NAME } from '@/constants';
  // import CustomInput from '@/components/inputs/custom-input.vue';

  export default {
    name: 'ApplicationItems',
    components: {
      ApplicationReminder,
      ModalBase
      // CustomInput
      // CharacterDetailsModal
    },
    props: ['currentApplication', 'remainingCredit', 'isApplicationHistory'],
    data: () => ({
      videoFile: null,
      testVideo: null,
      status: '',
      id: '',
      secondLayer: false,
      uploadedVideoFile: null,
      selectedItem: null,
      routeName: ROUTE_NAME,
      headers: [
        {
          text: '',
          sortable: false,
          value: 'role_name',
          width: '300px',
          key: 'role_name',
          cellClass: 'role-name_col'
        },
        {
          text: 'Interest',
          sortable: false,
          value: 'interest',
          width: '100px',
          key: 'interest'
        },
        {
          text: 'Video',
          sortable: false,
          value: 'video',
          width: '100px',
          key: 'video'
        },
        {
          text: 'Status',
          sortable: false,
          value: 'status',
          width: '100px',
          key: 'status'
        },
        {
          text: 'Details',
          sortable: false,
          value: 'details',
          cellClass: 'py-3',
          width: '15%',
          key: 'details'
        },
        {
          text: 'Union No',
          sortable: false,
          value: 'union_no',
          cellClass: 'py-3',
          width: '15%',
          key: 'union_no'
        },
        {
          text: '',
          sortable: false,
          cellClass: 'action-width_col py-3',
          value: 'action',
          key: 'action'
        },
        {
          text: '',
          sortable: false,
          cellClass: 'filter-width_col',
          value: 'filter',
          key: 'filter'
        }
      ],
      mobileListHeaders: [
        {
          text: '',
          sortable: false,
          value: 'role_name',
          width: '300px',
          key: 'role_name',
          cellClass: 'role-name_col'
        },
        {
          text: 'Interest',
          sortable: false,
          value: 'interest',
          width: '100px',
          key: 'interest'
        },
        {
          text: 'Video',
          sortable: false,
          value: 'video',
          width: '100px',
          key: 'video'
        },
        {
          text: 'Status',
          sortable: false,
          value: 'status',
          width: '100px',
          key: 'status'
        }
      ],
      mobileSecondLayerHeaders: [
        {
          text: '',
          sortable: false,
          value: 'role_name',
          width: '100%',
          key: 'role_name'
        }
      ]
    }),
    computed: {
      ...mapGetters({
        me: ['auth/me']
      }),
      isTalentRole() {
        return this?.me?.role === 'talent' || this?.me?.role === 'agency';
      },
      applicationTitle() {
        return this?.currentApplication[0].title;
      },
      applications() {
        const singleApp = this?.currentApplication[0] || {};
        const applications = [
          ...(singleApp?.applications || []),
          ...(singleApp?.invites || [])
        ];

        return applications.reduce((acc, current) => {
          const itemIndex = acc.findIndex(
            (i) => i.roleId == current.casting_role_id
          );
          let formattedCurrent = {
            ...current,
            video_status: current?.video_status || null
          };
          if (current.chat_group) {
            formattedCurrent.chat_group = {
              ...current.chat_group,
              users: current?.chat_group?.users?.map(
                (u) =>
                  ({
                    user_id: u.user_id,
                    name: u.display_name || u.company_name,
                    headshot_file_path:
                      u?.headshot_file?.media_path ||
                      this?.$store?.getters['constants/avatarImageUrl'],
                    is_included: u.is_included
                  } || [])
              )
            };
          }
          if (itemIndex >= 0) {
            acc[itemIndex].items.push(formattedCurrent);
          } else {
            acc.push({
              roleId: current.casting_role_id,
              roleInfo: current.role,
              items: [formattedCurrent]
            });
          }
          return acc;
        }, []);
      },
      application_status() {
        return {
          ...this?.$store?.getters['constants/applicationStatus'],
          pending: 'white'
        };
      },
      withdrawApplicationComplete() {
        return this.$store.state.applicationManagement.withdrawApplication
          .complete;
      }
    },
    watch: {
      withdrawApplicationComplete() {
        let response =
          this.$store.state.applicationManagement.withdrawApplication;
        if (response.complete) {
          this.withdrawApplicationCompleteAction(response);
        }
      }
    },
    methods: {
      resetModal() {
        this.status = '';
        this.id = '';
      },
      getUserNames(users = []) {
        const userNameArr = users.map((x) => x.name);
        return userNameArr.join(', ');
      },
      checkUserIncluded(chatGroupUsers) {
        const chatUser = chatGroupUsers.find((x) => x.user_id == this.me.id);
        return chatUser.is_included;
      },
      defaultDate(date) {
        return Moment(date).format(DEFAULT_DATE_FORMAT);
      },
      openPreviewVideoModal(e, item) {
        if (!e) return;

        bus.$emit('openPreviewVideoModal', {
          videoSrc: URL.createObjectURL(e.target.files[0]),
          videoFile: e.target.files[0],
          itemId: item.id,
          talentUid: item.talent.id,
          hasConfirmBtn: true
        });
      },
      previewVideoModalClose(e) {
        this.$closeModal();
        document.getElementById(`fileUpload-${e}`).value = null;
      },
      // confirmUploadVideo(e) {
      //   this.uploadVideo(e.compressed, {
      //     id: e.itemId,
      //     talent: {
      //       id: e.talentUid
      //     }
      //   })

      // },
      openChat(chatGroupId) {
        this.$openChat(chatGroupId);
      },
      async downloadVideo(videoLink) {
        this.$startLoading();
        try {
          const response = await fetch(videoLink);
          const blob = await response.blob();
          const newBlob = new Blob([blob]);

          const objUrl = window.URL.createObjectURL(newBlob);
          const link = document.createElement('a');
          link.href = objUrl;
          link.download = 'iamcasting-video.mp4';
          link.click();
          // For Firefox it is necessary to delay revoking the ObjectURL.
          setTimeout(() => {
            window.URL.revokeObjectURL(objUrl);
          }, 250);
        } catch (e) {
          console.error(e);
          this.openAppDialogInfo(
            'error',
            e.error_message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
        this.$stopLoading();
      },
      async actionBtn(application) {
        if (application.video_file) {
          // window.open(application.public_video_file);
          bus.$emit('openPreviewVideoModal', {
            videoSrc: application.public_video_file,
            videoFile: application.video_file,
            hasConfirmBtn: false
          });
        } else {
          this.$startLoading();
          try {
            await APPLICATION_MANAGEMENT.postVideoReminder(
              application.id || application.invitation_id
            );
            this.$openModal('messageModal', 'Reminder sent');
          } catch (e) {
            console.error(e);
            this.openAppDialogInfo(
              'error',
              e.error_message || 'Request Failed',
              '',
              [
                {
                  text: 'OK',
                  action: () => {
                    this.closeAppDialogInfo();
                  }
                }
              ]
            );
          }
          this.$stopLoading();
        }
      },
      async reinvite(jobId, application) {
        this.$startLoading();
        try {
          const payload = {
            job_id: jobId,
            role_id: application.casting_role_id,
            user_id: application.talent.id
          };
          await APPLICATION_MANAGEMENT.reinvite(payload);
          this.$openModal('messageModal', 'Reinvite sent');
        } catch (e) {
          console.error(e);
          this.openAppDialogInfo(
            'error',
            e.error_message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
        this.$stopLoading();
      },
      async requestNewVideo(status, application) {
        if (status == 'declined') {
          this.$startLoading();
          try {
            await APPLICATION_MANAGEMENT.requestNewVideo(application.id);
            this.$openModal('messageModal', 'Request new \nsubmission sent');
            this.selectedItem = null;
          } catch (e) {
            console.error(e);
            this.openAppDialogInfo(
              'error',
              e.error_message || 'Request Failed',
              '',
              [
                {
                  text: 'OK',
                  action: () => {
                    this.closeAppDialogInfo();
                  }
                }
              ]
            );
          }
          this.$stopLoading();
        } else {
          this.$openModal('applicationReminder');
          this.id = application.id;
          this.selectedItem = null;
        }
      },
      async updateVideoStatus(status) {
        this.$startLoading();
        try {
          await APPLICATION_MANAGEMENT.updateVideoStatus(this.selectedItem.id, {
            videoStatus: status
          });
          this.requestNewVideo(status, this.selectedItem);
          if (status != 'viewed') this.$emit('refresh');
        } catch (e) {
          console.error(e);
          this.openAppDialogInfo(
            'error',
            e.error_message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
        this.$stopLoading();
        if (status != 'viewed') this.$closeModal();
      },
      async approveApplication() {
        this.$startLoading();
        try {
          await APPLICATION_MANAGEMENT.updateApplicationStatus(
            this.id,
            this.status
          );
          this.$emit('refresh');
          this.$closeModal();
          this.$openModal('messageModal', 'Application Status \nUpdate Sent');
        } catch (e) {
          console.error(e);
          this.openAppDialogInfo(
            'error',
            e.error_message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
        this.$stopLoading();
      },
      async uploadVideo(e, application) {
        // const file = e.target.files[0];
        const file = e;
        if (file.size > 524288000) {
          this.openAppDialogInfo(
            'error',
            'Video file size must not be greater than 500MB.',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
          return;
        }
        this.$startLoading();
        this.$videoLoading();
        await APPLICATION_MANAGEMENT.uploadVideo(application.id, {
          video_file: file,
          talent_uid: application.talent.id
        })
          .then(() => {
            this.$emit('refresh');
            this.openAppDialogInfo(
              'success',
              'Video uploaded successfully',
              '',
              [
                {
                  text: 'OK',
                  action: () => {
                    this.closeAppDialogInfo();
                  }
                }
              ]
            );
          })
          .catch((e) => {
            console.error(e);
            this.openAppDialogInfo(
              'error',
              e.error_message ||
                'Upload Failed, Please check your internet connection and try again.',
              '',
              [
                {
                  text: 'OK',
                  action: () => {
                    this.closeAppDialogInfo();
                  }
                }
              ]
            );
          });
        this.$resetVideoLoading();
        this.$stopLoading();
        this.$refs[`videoFile-${application.id}`].value = '';
      },
      async invitationReminder(invitationId) {
        this.$startLoading();
        try {
          await APPLICATION_MANAGEMENT.sendInvitationApplicationReminder(
            invitationId
          );
          this.openAppDialogInfo('success', 'Reminder successfully sent', '', [
            {
              text: 'OK',
              action: () => {
                this.closeAppDialogInfo();
              }
            }
          ]);
        } catch (e) {
          console.error(e);
          this.openAppDialogInfo(
            'error',
            e.error_message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
        this.$stopLoading();
      },
      withdrawApplication(id) {
        this.$openModal(
          'messageModal',
          'Are you sure to withdraw this application?',
          {
            title: 'Yes',
            action: () => {
              this.$store.dispatch(
                APPLICATION_MANAGEMENT_WITHDRAW_APPLICATION,
                { id }
              );
              this.$closeModal();
            }
          },
          { show: true, customText: '' }
        );
      },
      withdrawApplicationCompleteAction(response) {
        const success = response.code == 200;
        this.openAppDialogInfo(
          success ? 'success' : 'error',
          success
            ? 'Sucessfully Withdrawn'
            : response.message || 'Request Failed',
          '',
          [
            {
              text: 'OK',
              action: () => {
                this.closeAppDialogInfo();
              }
            }
          ]
        );
        this.$store.dispatch(
          APPLICATION_MANAGEMENT_INITIAL_WITHDRAW_APPLICATION_STATE
        );
        this.$emit('refresh');
      },
      openFile(id) {
        if (!this.remainingCredit.upload && this.me?.role == 'talent') {
          this.$openModal(
            'messageModal',
            'Insufficient upload count. Please purchase video credits.',
            {
              title: 'Ok',
              action: () => {
                this.$router.push({
                  name: ROUTE_NAME.PAYMENT_GATEWAY,
                  query: {
                    type: 'token',
                    success_url:
                      '/application_management?job_id=' +
                      this.$route.query.job_id
                  }
                });
                this.$closeModal();
              }
            },
            { show: true, customText: '' }
          );
          return;
        }
        document.getElementById(`fileUpload-${id}`).click();
      },
      getButtonMessage(status) {
        const isProdHouseOrFreelancer = [
          'production_house',
          'freelancer'
        ].includes(this.me.role);
        let message = '';
        switch (status) {
          case 'viewed':
            message = isProdHouseOrFreelancer
              ? 'Video is approved'
              : 'Your video has been accepted';
            break;
          case 'declined':
            message = isProdHouseOrFreelancer
              ? 'Video is rejected'
              : 'Your previous video was rejected, please upload a new one';
            break;
          case 'pending' || null || undefined:
            if (isProdHouseOrFreelancer)
              message =
                'No video submitted yet, send a reminder to talent for video submission';
            else if (this.me.role == 'talent')
              message = 'Please upload a video';
            else
              message =
                'No video submitted yet. Upload one now or send a reminder to talent for video submission';
            break;
          default:
            message = isProdHouseOrFreelancer
              ? 'Video is submitted'
              : 'Your video is being reviewed';
            break;
        }
        return message;
      }
    },
    created() {
      bus.$on('refreshAM', () => {
        this.$emit('refresh');
      });
    }
  };
</script>
<style scoped lang="scss">
  td.role-name_col {
    max-width: 300px;
  }
  ::v-deep.action-width_col {
    width: 200px;
    max-width: 200px;
  }
  .filter-width_col {
    width: 300px;
    max-width: 300px;
  }
  .role-name_title {
    letter-spacing: 1px;
    color: white !important;
    &:hover {
      text-shadow: 0 0 white;
    }
  }
  .application_datatable:not(:first-of-type),
  .mobile-second-layer-application_datatable:not(:first-of-type) {
    margin-top: 2rem;
  }
  .application_datatable tbody tr {
    min-height: 150px;
    height: 150px;
  }
  .status_icon:hover {
    text-shadow: 0 0 inherit;
  }
  ::v-deep.theme--dark.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:hover:not(.v-data-table__expanded__content):not(
      .v-data-table__empty-wrapper
    ) {
    background-color: #272727 !important;
  }
  .mobile-second-layer-application_datatable tbody td {
    padding-top: 1rem !important;
    padding-bottom: 2rem !important;
  }
  .mobileButtonContainer {
    position: relative;
  }
  .mobileTooltipPosition {
    position: absolute !important;
    top: 50%;
    right: 10%;
    transform: translate(-10%, -50%);
  }

  .application-details_row > div.col:nth-of-type(even) {
    min-height: 50px;
    margin-bottom: 1rem;
  }

  .application-btn_column {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
</style>
